import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../redux/reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { verifyAuth } from "../pages/login/auth/authAction";

export const middlewares = [thunk];
const initialState = undefined;

export const configureStore = () => {
  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middlewares))
  );
  store.dispatch(verifyAuth());
  return store;
};
