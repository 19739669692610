import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import { FcGoogle } from "react-icons/fc";
import { googleLogin, newUserSignInSwitch } from "./auth/authAction";
import { ReactComponent as BarSVG } from "../../SVG/bars.svg";
import TitleHeader from "../../components/titleHeader/TitleHeader";

const useStyles = makeStyles({
  login: { marginTop: 100 },
  boxContainer: { maxWidth: 300, marginLeft: "auto", marginRight: "auto" },
  buttonContainer: {
    width: "100%",
  },
});

const Login = ({
  userData,
  googleLogin,
  newUserSignIn,
  newUserSignInSwitch,
}) => {
  const classes = useStyles();

  let history = useHistory();

  console.log("userData login", userData);
  useEffect(() => {
    if (newUserSignIn && userData.userId) {
      console.log("push to /setup");
      history.push("/setup");
      newUserSignInSwitch(false);
    } else if (userData.userId) {
      console.log("push to /");
      history.push("/");
    }
  }, [userData, history, newUserSignIn, newUserSignInSwitch]);

  console.log("newUserSignIn", newUserSignIn);

  return (
    <>
      <TitleHeader title='LOGIN' />

      {newUserSignIn ? (
        <Box textAlign='center' pt={6}>
          <BarSVG />
        </Box>
      ) : (
        <Box className={classes.login}>
          <Box boxShadow={4} className={classes.boxContainer}>
            <Button
              size='large'
              startIcon={<FcGoogle />}
              className={classes.buttonContainer}
              onClick={googleLogin}
            >
              SIGN IN WITH GOOGLE
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userData: state.userData,
  newUserSignIn: state.authData.newUserSignIn,
});

const mapDispatchToProps = {
  googleLogin,
  newUserSignInSwitch,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
