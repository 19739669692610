import React from "react";
import { Typography, Box } from "@material-ui/core";

const AdminContentUnitSlide = ({ content }) => {
  return (
    <Box p={1} border={1}>
      <Typography variant='body1'>CONTENT:</Typography>
      <Typography>{content}</Typography>
    </Box>
  );
};

export default AdminContentUnitSlide;
