import React from "react";
import { Box, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import { navButtonSelection } from "../../redux/action/navAction";

const LinearProgressBar = ({ currentSlide, numberOfSlide, page }) => {
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      flexDirection='row'
      data-test='test-main'
    >
      <Box data-test='test-x-button'>
        <Button
          component={RouterLink}
          to='/courses'
          onClick={() => {
            navButtonSelection(1);
          }}
        >
          <CloseIcon fontSize='large' />
        </Button>
      </Box>
      <Box flex={1}>
        <LinearProgress
          variant='determinate'
          value={((currentSlide ? currentSlide : page) / numberOfSlide) * 100}
          color='primary'
          data-test='test-progressbar'
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = (state, ownProps) => {
  const user = state.userData;

  const currentSlide =
    user.covid &&
    ownProps.topicUnitInfo &&
    user.covid.unit[ownProps.topicUnitInfo].currentSlide;

  return {
    currentSlide,
  };
};

const mapDispatchToProps = {
  navButtonSelection,
};

export default connect(mapStateToProps, mapDispatchToProps)(LinearProgressBar);
