import React from "react";
import { Box, Typography, Button } from "@material-ui/core";
import TitleHeader from "../../components/titleHeader/TitleHeader";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { navButtonSelection } from "../../redux/action/navAction";
import PropTypes from "prop-types";

// import { ReactComponent as SvgImport } from "../../svg-loaders/bars.svg";
// import { TailSpin } from "svg-loaders-react";

const Home = ({ navButtonSelection }) => {
  return (
    <div data-test='home-page'>
      <TitleHeader title='COVID-19 Infection Control Education' />
      <Box align='center' data-test='course-button'>
        <Button
          variant='contained'
          color='secondary'
          component={Link}
          to='/courses'
          onClick={() => navButtonSelection(1)}
        >
          VIEW COURSES
        </Button>
      </Box>
      <Box mt={4} data-test='first-content-box'>
        <Typography data-test='content'>
          Hillsborough County infection control education platform for
          healthcare workers and essential workers.
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography>
          Developed by USF healthcare professionals for workers on the frontline
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography>
          The app provides up-to-date infection control guideline and education
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography>
          Quickly and easily manage infection control education through this app
        </Typography>
      </Box>
    </div>
  );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = { navButtonSelection };

Home.propTypes = {
  navButtonSelection: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
