import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { Link as RouterLink } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";
import { slideCount } from "../../redux/action/slideAction";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchTopicUnit } from "../../redux/action/publicAction";

const useStyles = makeStyles({
  root: {
    backgroundColor: "whitesmoke",
    marginBottom: 20,
  },
  progressBar: {
    marginTop: 10,
    backgroundColor: "red",
  },
});

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
  },
  bar: {
    borderRadius: 20,
  },
})(LinearProgress);

function CourseUnit({
  unit,
  info,
  topic,
  progress,
  currentSlide,
  fetchTopicUnit,
}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea
        component={RouterLink}
        // if progress is 100% jump right away to finished page
        to={
          progress === 100
            ? "/finish"
            : `/slide/${topic}/${info}/${currentSlide}`
        }
        // onclick would trigger if progress is 100% and fetch topic unit to be populated for finish page
        onClick={() => (progress === 100 ? fetchTopicUnit(topic, info) : null)}
      >
        <CardContent>
          <Typography variant='body1' color='secondary'>
            {info && info}
          </Typography>
          <Typography variant='body2'>Unit {unit && unit}</Typography>

          {progress ? (
            <BorderLinearProgress
              className={classes.progressBar}
              variant='determinate'
              color='primary'
              value={progress}
            />
          ) : null}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = { slideCount, fetchTopicUnit };

CourseUnit.propTypes = {
  unit: PropTypes.number,
  info: PropTypes.string,
  topic: PropTypes.string,
  progress: PropTypes.number,
  currentSlide: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseUnit);
