import {
  IS_LOADING,
  NEW_USER_SIGNIN,
  SIGN_IN_USER,
  SIGN_OUT_USER,
} from "./authAction";

const initialState = {
  authenticated: false,
  currentUser: null,
  newUserSignIn: false,
  isLoading: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SIGN_IN_USER:
      return {
        ...state,
        authenticated: true,
        currentUser: {
          email: payload.email,
          photoURL: payload.photoURL,
          userId: payload.uid,
        },
      };

    case SIGN_OUT_USER:
      return {
        ...state,
        authenticated: false,
        currentUser: null,
      };

    case NEW_USER_SIGNIN:
      return {
        ...state,
        newUserSignIn: true,
      };
    case IS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };

    default:
      return state;
  }
};
