import location from "../../Data/selectData/location.json";
import position from "../../Data/selectData/position.json";
import role from "../../Data/selectData/role.json";
import coordinator from "../../Data/selectData/coordinator.json";
import { db } from "../../firebase/Firebase";

export const FETCH_POSITION = "FETCH_POSITION";
export const FETCH_LOCATION = "FETCH_LOCATION";
export const FETCH_ROLE = "FETCH_ROLE";
export const FETCH_COORD = "FETCH_COORD";
export const FETCH_COVID_LIST = "FETCH_COVID_LIST";
export const FETCH_TOPIC_UNIT = "FETCH_TOPIC_UNIT";
export const FETCH_TOPIC_UNIT_CLEAR = "FETCH_TOPIC_UNIT_CLEAR";
export const SHOW_NAV_BOTTOM = "SHOW_NAV_BOTTOM";
export const SAVE_TOPIC_UNIT = "SAVE_TOPIC_UNIT";

export const showNavBtm = (payload) => ({
  type: SHOW_NAV_BOTTOM,
  payload: payload,
});

export const fetchLocation = () => ({
  type: FETCH_LOCATION,
  payload: location,
});

export const fetchPosition = () => ({
  type: FETCH_POSITION,
  payload: position,
});

export const fetchRole = () => ({
  type: FETCH_ROLE,
  payload: role,
});

export const fetchCoord = () => ({
  type: FETCH_COORD,
  payload: coordinator,
});

export const fetchCovidList = () => async (dispatch) => {
  try {
    const covidList = await db.collection("covid").doc("covidList").get();

    console.log(`%c DB:fetchCovidList`, "color: #3373FF");

    if (covidList.data()) {
      dispatch({ type: FETCH_COVID_LIST, payload: covidList.data() });
      return covidList.data();
    }
  } catch (error) {
    console.log("CovidList does not exist", error);
  }
};

export const fetchTopicUnit = (topic, info) => async (dispatch, getState) => {
  //check to see if topic unit is already inside savedTopicUnit store
  const savedTopicUnitData = getState().publicData.savedTopicUnit;

  if (Object.keys(savedTopicUnitData).includes(info)) {
    const reuseOldTopicUnit = savedTopicUnitData[info];
    console.log("savedTopicUnit exist");
    // if unit already exist, dispatch old unit as fetch topic unit
    return dispatch({ type: FETCH_TOPIC_UNIT, payload: reuseOldTopicUnit });
  } else {
    // if unit does not exist, fetch from database a new topic unit
    // also saved new unit to savedTopicUnit
    let topicUnit;

    try {
      topicUnit = await db.collection(topic).doc(info).get();
      topicUnit = topicUnit.data();
      if (topicUnit) {
        console.log(`%cDB: fetchTopic Unit data`, "color: #3373FF");
        dispatch({ type: FETCH_TOPIC_UNIT, payload: topicUnit });
        // save topic to local redux cache to prevent refetching
        await dispatch(saveTopicUnit(topicUnit, info));
        return topicUnit;
      } else {
        console.log(`%c DB:fetch topicUnit does not exist`, "color: #3373FF");
      }
    } catch (error) {
      console.log(error);
    }
  }
};

// fetched topic list is saved to saveTopicUnit for local cache
export const saveTopicUnit = (fetchedTopicUnit, info) => async (
  dispatch,
  getState
) => {
  const publicData = getState().publicData;
  const newSavedTopicUnit = {
    ...publicData.savedTopicUnit,
    [info]: fetchedTopicUnit,
  };

  dispatch({ type: SAVE_TOPIC_UNIT, payload: newSavedTopicUnit });
};
