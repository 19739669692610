import React, { useEffect } from "react";
import { connect } from "react-redux";
import { fetchCovidList } from "../../../redux/action/publicAction";
import CovidList from "./CovidList";
import AdminContentUnit from "./AdminContentUnit";
import { fetchAllUnit } from "./redux/adminAction";
import _ from "lodash";

const AdminContent = ({
  fetchCovidList,
  covidList,
  covidListUnitOrder,
  fetchAllUnit,
  allUnitListOrder,
}) => {
  useEffect(() => {
    fetchCovidList();
    fetchAllUnit();
  }, [fetchCovidList, fetchAllUnit]);

  return (
    <>
      <CovidList
        covidList={covidList}
        covidListUnitOrder={covidListUnitOrder}
      />

      <AdminContentUnit allUnitList={allUnitListOrder} />
    </>
  );
};

const mapStateToProps = (state) => {
  let covidListUnit = state.publicData.covidList.unit;

  const covidListUnitOrder = _.orderBy(covidListUnit, ["unit"], ["asc"]);

  let allUnitListOrder = _.orderBy(state.adminData.allUnit, ["unit"], ["asc"]);

  return {
    covidListUnitOrder,
    covidList: state.publicData.covidList,
    allUnitListOrder,
  };
};

const mapDispatchToProps = { fetchCovidList, fetchAllUnit };

export default connect(mapStateToProps, mapDispatchToProps)(AdminContent);
