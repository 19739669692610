import { Box, Button } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import TitleHeader from "../../components/titleHeader/TitleHeader";

const RouterDefault = () => {
  return (
    <>
      <TitleHeader title='PAGE NOT FOUND' />

      <Box align='center' pt={3}>
        <Button
          component={RouterLink}
          to='/'
          variant='contained'
          color='secondary'
          size='large'
        >
          Go To Home
        </Button>
      </Box>
    </>
  );
};

export default RouterDefault;
