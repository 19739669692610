import React from "react";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Box, makeStyles, useTheme, Button } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
  icon: { marginLeft: 10, marginRight: 10 },
  forward: { flex: 3 },
  backward: { flex: 1 },
}));

const ButtonArrow = ({
  forwardClick,
  backwardClick,
  forwardDisable,
  backwardDisable,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Box display='flex' justifyContent='space-around'>
      <Button
        size='large'
        disabled={backwardDisable}
        onClick={backwardClick}
        className={`${classes.backward}  ${classes.icon}`}
        variant='contained'
        style={{
          backgroundColor: theme.palette.secondary.light,
          color: "white",
        }}
      >
        <ArrowBackIcon />
      </Button>

      {forwardDisable ? (
        <Button
          size='large'
          component={RouterLink}
          to='/finish'
          className={`${classes.forward}  ${classes.icon}`}
          variant='contained'
          style={{
            backgroundColor: theme.palette.secondary.light,
            color: "white",
            fontWeight: "bolder",
          }}
        >
          Finish
        </Button>
      ) : (
        <Button
          size='large'
          onClick={forwardClick}
          className={`${classes.forward}  ${classes.icon}`}
          variant='contained'
          style={{
            backgroundColor: theme.palette.secondary.light,
            color: "white",
          }}
        >
          <ArrowForwardIcon />
        </Button>
      )}
    </Box>
  );
};

export default ButtonArrow;
