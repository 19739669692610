import { createMuiTheme } from "@material-ui/core/styles";
import yellow from "@material-ui/core/colors/yellow";
import blue from "@material-ui/core/colors/blue";

export const theme = createMuiTheme({
  palette: {
    primary: yellow,
    secondary: blue,
  },
  typography: {
    fontSize: 14,
    fontFamily: "Roboto,'Open Sans', sans-serif",
  },

  // overrides: {
  //   MuiBottomNavigationAction: {
  //     root: {
  //       "&$selected": {
  //         color: "#2979ff",
  //       },
  //     },
  //   },
  // },
});
