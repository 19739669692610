import React, { useEffect } from "react";
import "./style/App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollToTop from "./components/others/ScrollToTop";
import Nav from "./components/nav/Nav";
import { connect, useDispatch, useSelector } from "react-redux";
import { fetchUserData } from "./redux/action/userAction";
import {
  fetchCoord,
  fetchRole,
  fetchPosition,
  fetchLocation,
} from "./redux/action/publicAction";
import { makeStyles } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core/styles";
import Account from "./pages/account/Account";
import Home from "./pages/home/Home";
import Slide from "./pages/slide/Slide";
import Courses from "./pages/courses/Course";
import About from "./pages/about/About";
import EditAccount from "./pages/account/EditAccount";
import Login from "./pages/login/Login";
import Admin from "./pages/admin/Admin";
import AdminEditAccount from "./pages/admin/user/AdminEditAccount";
import AdminContentUnitAddNew from "./pages/admin/content/AdminContentUnitAddNew";
import Coordinator from "./pages/coordinator/Coordinator";
import CovidListEdit from "./pages/admin/content/CovidListEdit";
import Finish from "./pages/finish/Finish";
import { theme as styleTheme } from "./style/style";
import ContainerX from "./components/ContainerX";
import { ReactQueryDevtools } from "react-query-devtools";
import AdminContentUnitEdit from "./pages/admin/content/AdminContentUnitEdit";
import Setup from "./pages/setup/Setup";
import ProtectedRoutes from "./components/ProtectedRoutes";
import RouterDefault from "./pages/routerDefault/RouterDefault";

// import "./firebaseui-styling.global.css"; // Import globally.

// automatic responsive font sizes based on variant
// https://material-ui.com/customization/typography/

const useStyles = makeStyles((theme) => ({
  normalLinks: {
    maxWidth: 700,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.only("xs")]: {
      maxWidth: 400,
    },
  },
}));

function App() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const authenticated = useSelector((state) => state.authData.authenticated);
  console.log("authenticated", authenticated);

  useEffect(() => {
    dispatch(fetchCoord());
    dispatch(fetchRole());
    dispatch(fetchPosition());
    dispatch(fetchLocation());
  }, [dispatch]);

  return (
    <Router>
      <ThemeProvider theme={styleTheme}>
        <Nav />
        <ScrollToTop>
          <Switch>
            <>
              <div className={classes.normalLinks}>
                <Switch>
                  <Route exact path='/'>
                    <ContainerX showNavBtm>
                      <Home />
                    </ContainerX>
                  </Route>
                  <Route path='/login'>
                    <ContainerX showNavBtm>
                      <Login />
                    </ContainerX>
                  </Route>
                  <Route path='/slide/:topic/:info/:slidenum?'>
                    <ContainerX>
                      <Slide />
                    </ContainerX>
                  </Route>
                  <Route path='/account'>
                    <ProtectedRoutes auth={authenticated}>
                      <ContainerX showNavBtm>
                        <Account />
                      </ContainerX>
                    </ProtectedRoutes>
                  </Route>
                  <Route path='/accountEdit'>
                    <ProtectedRoutes auth={authenticated}>
                      <ContainerX showNavBtm>
                        <EditAccount />
                      </ContainerX>
                    </ProtectedRoutes>
                  </Route>
                  <Route path='/courses'>
                    <ContainerX showNavBtm>
                      <Courses />
                    </ContainerX>
                  </Route>
                  <Route path='/about'>
                    <ContainerX showNavBtm>
                      <About />
                    </ContainerX>
                  </Route>
                  <Route path='/finish'>
                    <ContainerX>
                      <Finish />
                    </ContainerX>
                  </Route>
                  <Route path='/setup'>
                    <ProtectedRoutes auth={authenticated}>
                      <ContainerX>
                        <Setup />
                      </ContainerX>
                    </ProtectedRoutes>
                  </Route>
                  <Route path='/admin'>
                    <ProtectedRoutes auth={authenticated}>
                      <Admin />
                    </ProtectedRoutes>
                  </Route>
                  <Route path='/coordinator'>
                    <ProtectedRoutes auth={authenticated}>
                      <Coordinator />
                    </ProtectedRoutes>
                  </Route>
                  <Route
                    path='/admineditaccount/:userId'
                    render={(props) => (
                      <ProtectedRoutes auth={authenticated}>
                        <AdminEditAccount {...props} />
                      </ProtectedRoutes>
                    )}
                  />
                  <Route path='/admincontentunitaddnew'>
                    <ProtectedRoutes auth={authenticated}>
                      <AdminContentUnitAddNew />
                    </ProtectedRoutes>
                  </Route>
                  <Route path='/admincontentunitedit/:topic/:info/'>
                    <ProtectedRoutes auth={authenticated}>
                      <AdminContentUnitEdit />
                    </ProtectedRoutes>
                  </Route>
                  <Route path='/covidlistedit'>
                    <ProtectedRoutes auth={authenticated}>
                      <CovidListEdit />
                    </ProtectedRoutes>
                  </Route>
                  <Route>
                    <ContainerX>
                      <RouterDefault />
                    </ContainerX>
                  </Route>
                </Switch>
              </div>
            </>
          </Switch>
        </ScrollToTop>
        <ReactQueryDevtools initialIsOpen={false} />
      </ThemeProvider>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.userData,
    showNavBtm: state.publicData.showNavBtm,
  };
};

const mapDispatchToProps = {
  fetchUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
