import { combineReducers } from "redux";
import userReducer from "./userReducer";
import slideReducer from "./slideReducer";
import coordinatorReducer from "./coordinatorReducer";
import adminReducer from "../../pages/admin/content/redux/adminReducer"
import publicReducer from "./publicReducer";
import navReducer from "./navReducer";
import authReducer from "../../pages/login/auth/authReducer";

const rootReducer = combineReducers({
  userData: userReducer,
  slideData: slideReducer,
  adminData: adminReducer,
  coordinatorUserData: coordinatorReducer,
  publicData: publicReducer,
  navData: navReducer,
  authData: authReducer,
});

export default rootReducer;
