import { db } from "../../firebase/Firebase";
import { fetchUserData } from "./userAction";
import _ from "lodash";

export const addNewUnitToUserData = (unitToBeAdded) => (dispatch, getState) => {
  const userData = _.cloneDeep(getState().userData);
  const topicName = "covid";
  const userId = userData.userId;

  let topic = userData[topicName];

  // get the old unit from userData
  let oldUnit = topic && topic.unit;

  // add currentSlide:0 to unit to be added
  let addCurrentSlideToNewUnit = unitToBeAdded.map((item) => {
    return { ...item, currentSlide: 0 };
  });

  // convert addCurrentSlideToNewUnit into an object with item.info as key
  let newUnit = {};
  addCurrentSlideToNewUnit.forEach((item) => {
    newUnit[item.info] = item;
  });

  // merge oldunit in with newunit
  newUnit = { ...oldUnit, ...newUnit };

  if (userId) {
    db.collection("usersIPC")
      .doc(userId)
      .update({
        [`${topicName}.unit`]: newUnit,
      })
      .then(function () {
        console.log(
          `%c DB:addNewUnitToUserData, document successfully updated!`,
          "color: #3373FF"
        );
      })
      .then(() => dispatch(fetchUserData()))
      .catch((err) => {
        console.info("Document did not update correctly", err);
      });
  }
};
