import React from "react";
import CourseUnit from "./CourseUnit";
import { makeStyles } from "@material-ui/core/styles";

import { Box, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import TitleHeader from "../../components/titleHeader/TitleHeader";
import { fetchQueryCovidList } from "../../reactQuery/fetchData";
import { useQuery } from "react-query";
import Skeleton from "@material-ui/lab/Skeleton";
import { addNewUnitToUserData } from "../../redux/action/courseAction";
import _ from "lodash";
import { fetchUserData } from "../../redux/action/userAction";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  courses: {
    marginTop: 20,
  },
});

export const Courses = ({ userData, addNewUnitToUserData }) => {
  const classes = useStyles();

  // REACT QUERY DATA FETCH AND SYNC
  const { data, isLoading, error } = useQuery(
    "covidList",
    fetchQueryCovidList,
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
      onSuccess: () => console.log("covidList data fetched with no problem"),
      force: true,
    }
  );

  if (isLoading)
    return (
      <div data-test='course-page'>
        <TitleHeader title='COURSES' />
        <Skeleton width='100%' height={300} />
      </div>
    );

  if (error) return "An error has occurred " + error.message;

  // put user data into dataList for it to render progressbar
  // user data currentSlide, totalSlide should be added to dataList

  // only run this logic if user is logged in
  if (userData.covid) {
    const listDataObj = Object.values(data.unit);

    //check to see if user's unit is missing any unit from listdata
    //if user's unit is missing, send a action to add new unit

    const userDataInfo = _.map(userData.covid.unit, "info");

    // loop through listDatObj array and see if info value
    // is included in userDataInfo array
    // if not included, call action to add listDataObj item into database

    // gather all Unit that's needed to be added to user's unit Database
    let addNewUnitList = [];

    listDataObj.forEach((item, index) => {
      let includedItem = userDataInfo.includes(item.info);
      if (includedItem === false) {
        addNewUnitList.push(item);
      }

      // return !!includedItem ? null : addNewUnitToUserData(item);
    });

    if (addNewUnitList.length > 0) {
      console.log("addNewUnitList.length", addNewUnitList.length);
      addNewUnitToUserData(addNewUnitList);
    }
  }

  let course = userData.covid ? userData.covid : data;
  let courseArray = course.unit && Object.values(course.unit);

  const sortedCourseArray = _.sortBy(courseArray, ["unit"]);

  const courseMap = course && (
    <Box className={classes.courses} data-test='map-box'>
      <Box pb={3}>
        <Typography variant='h5'>{course.title}</Typography>
      </Box>

      {course.unit &&
        sortedCourseArray.map((courseUnit, index) => {
          const progressLogic = courseUnit.currentSlide
            ? (courseUnit.currentSlide / courseUnit.totalSlide) * 100
            : null;
          const courseUnitLogic = courseUnit.currentSlide
            ? courseUnit.currentSlide
            : null;

          return (
            <CourseUnit
              unit={courseUnit.unit}
              info={courseUnit.info}
              topic={course.topic}
              progress={progressLogic}
              currentSlide={courseUnitLogic}
              key={courseUnit.info + index}
              clickCourseCall={() => {}}
            />
          );
        })}
    </Box>
  );

  return (
    <div data-test='course-page'>
      <TitleHeader title='COURSES' />
      {courseMap}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userCourse: state.userData.savedTopic,
  userData: state.userData,
});

const mapDispatchToProps = { addNewUnitToUserData, fetchUserData };

Courses.propTypes = {
  userData: PropTypes.object,
  addNewUnitToUserData: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
