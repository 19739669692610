import React, { useEffect, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Paper, TextField, Button } from "@material-ui/core";
import { Link, useParams } from "react-router-dom";
import { connect } from "react-redux";

import TitleHeader from "../../../components/titleHeader/TitleHeader";
import { fetchTopicUnit } from "../../../redux/action";
import { editCurrentUnit } from "./redux/adminAction";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles({
  root: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  field: {
    width: "100%",
    textAlign: "left",
  },
  menuItem: {
    textAlign: "left",
  },
  MR: {
    marginRight: 40,
  },
  buttonBox: {
    marginTop: 100,
  },
  containerBox: {
    maxWidth: 360,
    marginTop: 20,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
});

const AdminContentUnitAddNew = ({
  topicUnit,
  fetchTopicUnit,
  editCurrentUnit,
}) => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);

  const { topic, info } = useParams();

  useEffect(() => {
    fetchTopicUnit(topic, info).then(() => {
      setIsLoading(false);
    });
  }, [fetchTopicUnit, topic, info]);

  const { register, handleSubmit, control, reset } = useForm({
    defaultValues: {
      slide: topicUnit.slide,
    },
  });

  console.log("topicUnit", topicUnit);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "slide",
  });

  useEffect(() => {
    if (!!topicUnit.slide) {
      console.log("reset");
      reset({ slide: topicUnit.slide });
    }
  }, [reset, topicUnit.slide, topicUnit]);
  console.log("fields", fields);

  const onSubmit = (data) => {
    if (data.list === "true") {
      data.list = true;
    } else {
      data.list = false;
    }

    data.unit = parseInt(data.unit);

    editCurrentUnit("covid", data.info, data);
    // addNewUnit("covid", data.info, data);

    return console.log("submitted data", data);
  };

  // if (Object.keys(topicUnit).length === 0) {
  //   return "Loading";
  // }

  if (isLoading) {
    return (
      <div data-test='test-loadingPlaceHolder'>
        <Box display='flex' justifyContent='center'>
          <Box width={450}>
            <Skeleton animation='wave' height={600} />
          </Box>
        </Box>
      </div>
    );
  }

  return (
    <div>
      <TitleHeader title='EDIT UNIT' />
      <form
        className={classes.root}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box component={Paper} className={classes.containerBox}>
          <Box pt={4}>
            <TextField
              size='small'
              color='secondary'
              className={classes.field}
              required
              disabled
              label='title'
              defaultValue={topicUnit.title}
              name='title'
              variant='outlined'
              inputRef={register({ required: true })}
            />
          </Box>

          <Box pt={4}>
            <TextField
              size='small'
              color='secondary'
              className={classes.field}
              required
              disabled
              label='topic'
              defaultValue={topicUnit.topic}
              name='topic'
              variant='outlined'
              inputRef={register({ required: true })}
            />
          </Box>

          <Box pt={4}>
            <TextField
              size='small'
              color='secondary'
              className={classes.field}
              required
              label='date'
              defaultValue={topicUnit.date}
              name='date'
              variant='outlined'
              inputRef={register({ required: true })}
            />
          </Box>
          <Box pt={4}>
            <TextField
              size='small'
              color='secondary'
              className={classes.field}
              required
              label='list'
              defaultValue={topicUnit.list}
              name='list'
              variant='outlined'
              inputRef={register({ required: true })}
            />
          </Box>
          <Box pt={4}>
            <TextField
              size='small'
              color='secondary'
              className={classes.field}
              required
              label='info'
              defaultValue={topicUnit.info}
              name='info'
              inputRef={register({ required: true })}
            />
          </Box>
          <Box pt={4}>
            <TextField
              size='small'
              color='secondary'
              className={classes.field}
              required
              label='unit'
              defaultValue={topicUnit.unit}
              name='unit'
              inputRef={register({ required: true })}
            />
          </Box>
        </Box>
        <Box component={Paper} className={classes.containerBox}>
          <Box mt={2}>
            {fields.map((item, index) => {
              return (
                <Box key={index} px={1} py={1} mt={2}>
                  <Box textAlign='left'>
                    Slide #{index + 1}
                    <TextField
                      size='small'
                      multiline
                      className={classes.field}
                      required
                      label='content'
                      name={`slide[${index}].content`}
                      defaultValue={item.content} // make sure to set up defaultValue
                      inputRef={register({ required: true })}
                      color='secondary'
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>

          <Box mt={3}>
            <Button
              size='small'
              color='secondary'
              variant='outlined'
              className={classes.MR}
              onClick={() => {
                append({ content: "" });
              }}
            >
              ADD SLIDE
            </Button>
            <Button
              size='small'
              color='secondary'
              variant='outlined'
              disabled={fields.length <= 0 ? true : false}
              onClick={() => {
                remove(fields.length - 1);
              }}
            >
              DELETE SLIDE
            </Button>
          </Box>
        </Box>

        <Box m={4}>
          <Button
            component={Link}
            color='secondary'
            variant='outlined'
            to='/admin/content'
            className={classes.MR}
          >
            Cancel
          </Button>
          <Button color='secondary' variant='outlined' type='submit'>
            Submit
          </Button>
        </Box>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  topicUnit: state.publicData.topicUnit,
});

const mapDispatchToProps = { fetchTopicUnit, editCurrentUnit };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminContentUnitAddNew);
