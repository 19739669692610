import React from "react";
import {
  Typography,
  Paper,
  Box,
  Button,
  Accordion,
  AccordionSummary,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AdminContentUnitSlide from "./AdminContentUnitSlide";
import { connect, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

import AlertDialog from "../../../components/others/AlertDialog";
import TitleHeader from "../../../components/titleHeader/TitleHeader";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { deleteUnit } from "./redux/adminAction";

const useStyles = makeStyles({
  covidList: {
    paddingLeft: 10,
  },
  unitContainer: {
    padding: 10,
    marginBottom: 30,
  },
  button: {
    marginRight: 10,
  },
  accordion: {
    marginTop: 20,
    marginBottom: 10,
  },
  accordionSummary: {
    backgroundColor: "whitesmoke",
  },
});

const AdminContentUnit = ({ allUnitList }) => {
  const dispatch = useDispatch();

  console.log("allUnitList", allUnitList);

  const classes = useStyles();

  const slideMap = (array) => {
    return array.map((item, index) => (
      <Box mt={3} key={index}>
        Slide #{index + 1}
        <AdminContentUnitSlide content={item.content} />
      </Box>
    ));
  };

  const allUnitListMap = allUnitList.map((item, index) => (
    <Paper className={classes.unitContainer} elevation={3} key={item.unit}>
      <Typography>Info: {item.info}</Typography>
      <Typography>Title: {item.title}</Typography>
      <Typography>Date: {item.date}</Typography>
      <Typography>Topic: {item.topic}</Typography>
      <Typography>Unit: {item.unit}</Typography>
      <Button
        color='secondary'
        variant='outlined'
        size='small'
        className={classes.button}
        component={RouterLink}
        to={`/admincontentunitedit/${item.topic}/${item.info}`}
      >
        Edit
      </Button>

      <AlertDialog
        alertButtonText='DELETE'
        alertDialogTitle='CONFIRM DELETE'
        alertDialogDescription='Are you sure you want to delete this unit?'
        handleCancelButtonText='Cancel'
        handleConfirmButtonText='Delete'
        className={classes.button}
        confirmActionFunction={() => {
          dispatch(deleteUnit(item.topic, item.info));
        }}
        size='small'
      />

      <Accordion className={classes.accordion}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
          className={classes.accordionSummary}
        >
          <Typography>Slides</Typography>
        </AccordionSummary>
        <Box px={2}>{slideMap(item.slide)}</Box>
      </Accordion>
    </Paper>
  ));

  return (
    <Box mt={5}>
      {<TitleHeader title='UNITS' />}
      <Box pb={2}>
        <Button
          variant='outlined'
          color='secondary'
          component={RouterLink}
          to='/admincontentunitaddnew'
        >
          Add New Unit
        </Button>
      </Box>
      {!!allUnitList.length ? allUnitListMap : "Loading"}
    </Box>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AdminContentUnit);
