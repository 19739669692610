import React from "react";
import { Box, Typography } from "@material-ui/core";
import TitleHeader from "../../components/titleHeader/TitleHeader";
import { AboutData, Contact } from "./AboutData";
import AboutUnit from "./AboutUnit";

const About = () => {
  const data = Object.values(AboutData);
  return (
    <>
      <TitleHeader title='ABOUT' />
      {data.map((item) => {
        return (
          <AboutUnit
            title={item.title}
            content={item.content}
            key={item.title}
          />
        );
      })}

      <Box mt={2}>
        <Typography variant='h5'>{Contact.title}</Typography>
        <Typography>{Contact.name}</Typography>
        <Typography>{Contact.email}</Typography>
      </Box>
    </>
  );
};

export default About;
