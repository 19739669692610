import React, { useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Paper,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { fetchCovidList } from "../../../redux/action/publicAction";
import TitleHeader from "../../../components/titleHeader/TitleHeader";
import _ from "lodash";
import { covidEditList } from "./redux/adminAction";

const useStyles = makeStyles({
  root: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  field: {
    width: "100%",
    textAlign: "left",
  },
  menuItem: {
    textAlign: "left",
  },
  MR: {
    marginRight: 40,
  },
  buttonBox: {
    marginTop: 100,
  },
  containerBox: {
    maxWidth: 500,
    marginTop: 20,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  formControl: {
    width: "100%",
    textAlign: "left",
  },
});

const CovidListEdit = ({ fetchCovidList, covidEditList, covidListUnit }) => {
  const classes = useStyles();

  useEffect(() => {
    fetchCovidList();
  }, [fetchCovidList]);

  const { register, handleSubmit, control, reset } = useForm({
    defaultValues: {
      covidList: covidListUnit,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "covidList",
  });

  useEffect(() => {
    if (!!covidListUnit) {
      reset({ covidList: covidListUnit });
    }
  }, [reset, covidListUnit]);

  const onSubmit = (data) => {
    console.log("data", data.covidList);

    //convert number that are type string into numbers types
    data.covidList.forEach((item) => {
      item.unit = parseInt(item.unit);
      item.totalSlide = parseInt(item.totalSlide);
    });
    let objectData = {};

    //converts an array into an object
    data.covidList.forEach((item, index) => {
      return (objectData = { ...objectData, [index]: item });
    });

    covidEditList(objectData);

    return console.log("submitted data", objectData);
  };

  return (
    <>
      <TitleHeader title='EDIT COVIDLIST' />
      <form
        className={classes.root}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box className={classes.containerBox}>
          <Box>
            {fields.map((item, index) => {
              return (
                <Box component={Paper} key={index} mt={3} p={3}>
                  <Box>
                    <TextField
                      size='small'
                      className={classes.field}
                      required
                      label='Info'
                      name={`covidList[${index}].info`}
                      defaultValue={item.info} // make sure to set up defaultValue
                      inputRef={register({ required: true })}
                      color='secondary'
                    />
                  </Box>
                  <Box pt={2}>
                    <TextField
                      size='small'
                      className={classes.field}
                      required
                      label='Unit'
                      type='number'
                      name={`covidList[${index}].unit`}
                      defaultValue={item.unit} // make sure to set up defaultValue
                      inputRef={register({ required: true })}
                      color='secondary'
                    />
                  </Box>
                  <Box pt={2}>
                    <TextField
                      size='small'
                      className={classes.field}
                      label='Total Slide'
                      name={`covidList[${index}].totalSlide`}
                      defaultValue={item.totalSlide} // make sure to set up defaultValue
                      inputRef={register()}
                      color='secondary'
                    />
                  </Box>

                  <Box pt={2}>
                    <FormControl className={classes.formControl}>
                      <InputLabel id='public' color='secondary'>
                        Public
                      </InputLabel>
                      <Controller
                        name={`covidList[${index}].public`}
                        control={control}
                        defaultValue={item.public || "false"}
                        as={
                          <Select
                            labelId='demo-simple-select-label'
                            id='public-select'
                            color='secondary'
                          >
                            <MenuItem value={"false"}>false</MenuItem>
                            <MenuItem value={"true"}>true</MenuItem>
                          </Select>
                        }
                      />
                    </FormControl>
                  </Box>
                </Box>
              );
            })}
          </Box>

          <Box mt={3}>
            <Button
              size='small'
              color='secondary'
              variant='outlined'
              className={classes.MR}
              onClick={() => {
                append({ unit: "", info: "", totalSlide: "", public: false });
              }}
            >
              ADD ITEM
            </Button>
            <Button
              size='small'
              color='secondary'
              variant='outlined'
              disabled={fields.length <= 1 ? true : false}
              onClick={() => {
                remove(fields.length - 1);
              }}
            >
              DELETE LAST ITEM
            </Button>
          </Box>
        </Box>

        <Box className={classes.buttonBox}>
          <Button
            component={Link}
            color='secondary'
            variant='outlined'
            to='/admin/content'
            className={classes.MR}
          >
            Cancel
          </Button>
          <Button color='secondary' variant='outlined' type='submit'>
            Submit
          </Button>
        </Box>
      </form>
    </>
  );
};

const mapStateToProps = (state) => {
  let covidListUnit = state.publicData.covidList.unit;

  covidListUnit = _.orderBy(covidListUnit, ["unit"], ["asc"]);
  return {
    covidListUnit,
  };
};

const mapDispatchToProps = { fetchCovidList, covidEditList };

export default connect(mapStateToProps, mapDispatchToProps)(CovidListEdit);
