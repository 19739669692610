import { Button } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";

const useStyles = makeStyles({
  button: {
    fontSize: 17,
    // fontWeight: 600,
    paddingLeft: 10,
    paddingRight: 10,
  },
});

const NavTop = ({ auth }) => {
  const classes = useStyles();
  return (
    <>
      <Button component={RouterLink} to='/' className={classes.button}>
        Home
      </Button>
      <Button component={RouterLink} to='/courses' className={classes.button}>
        Courses
      </Button>
      <Button component={RouterLink} to='/about' className={classes.button}>
        About
      </Button>

      {auth && (
        <Button component={RouterLink} to='/account' className={classes.button}>
          Account
        </Button>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  auth: state.authData.authenticated,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavTop);
