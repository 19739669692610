export const AboutData = {
  Mission: {
    title: "Mission & Goal",
    content:
      "The project's goal is to provide high quality and up-to-date infection control education for healthcare workers and essential works in Hillsborough County. The project will also help infection control education coordinators better manage their workforce's education.",
  },
  Scientific: {
    title: "Scientific Accuracy",
    content:
      "All information has been reviewed by University of South Florida healthcare professionals in infection control, infectious disease and public health.",
  },
  National: {
    title: "National and Local Guideline",
    content:
      "The majority of the information are sourced from peer reviewed scientific articles, CDC guidelines, and APIC guidelines. Local guidelines come from Florida State Health Department and Hillsborough  County Health Department",
  },
};

export const Contact = {
  title: "Contact",
  name: "Dat Nguyen",
  email: "Dat7MD@gmail.com",
};
