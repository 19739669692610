import history from "./../../components/others/history";
import { db, auth, firebase } from "../../firebase/Firebase";

export const USER_DATA = "USER_DATA";
export const LOGOUT = "LOGOUT";
export const DELETE_USER = "DELETE_USER";

export const logOut = () => async (dispatch) => {
  try {
    await auth.signOut();

    console.log("logout successful");
  } catch (error) {
    console.log("error", error);
  }

  dispatch({
    type: LOGOUT,
  });
};

export const deleteUser = () => (dispatch) => {
  auth.currentUser
    .reauthenticateWithPopup(new firebase.auth.GoogleAuthProvider())
    .then((userResult) => {
      console.log(
        `%c DB:account is deleted`,
        userResult.user.displayName,
        "color: #3373FF"
      );
      auth.currentUser.delete();
    })
    .then(() => {
      //****testing
      console.log("redirecting to push /");
      history.push("/");
      return window.location.reload();
    })
    .catch((error) => {
      console.log("error", error);
    });
  dispatch({
    type: DELETE_USER,
  });
};

export const fetchUserData = () => (dispatch, getState) => {
  console.log("fetchUserData");
  const userId = getState().authData.currentUser.userId;

  if (userId) {
    // get the user's data from the userIPC collection
    db.collection("usersIPC")
      .doc(userId)
      .get()
      .then((doc) => {
        if (doc.exists) {
          console.log(
            `%c DB:fetchUserData User data`,
            "color: #3373FF",
            doc.data()
          );
          dispatch({ type: USER_DATA, payload: doc.data() });
        } else {
          console.log("fetchUserData No such user data!");
        }
      });
  } else {
    console.log("fetchUserData you are signed out");
  }
};

export const editUserData = (data, adminUserId) => async (
  dispatch,
  getState
) => {
  const userData = getState().userData;
  const userId = adminUserId ? adminUserId : userData.userId;

  try {
    await db.collection("usersIPC").doc(userId).update(data);

    console.log(`%c DB:Edit user account data successful`, "color: #3373FF");
  } catch (error) {
    console.log("error", error);
  }

  dispatch(fetchUserData());
};
