import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import SettingsIcon from "@material-ui/icons/Settings";
import ListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { navButtonSelection } from "../../redux/action/navAction";

const useStyles = makeStyles((theme) => ({
  bottomNav: {
    width: "100%",
    // height: 300,
    marginLeft: "auto",
    marginRight: "auto",
    position: "relative",
    // [theme.breakpoints.down("xs")]: {
    //   top: 5,
    // },
  },
  NavigationAction: {
    // paddingBottom: 20,
  },
}));

function SimpleBottomNavigation({
  user,
  navButtonSelectionData,
  navButtonSelection,
}) {
  const classes = useStyles();
  let location = useLocation();

  let value = () => {
    // this allows refresh to keep same nav location
    if (location.pathname === "/courses") {
      return 1;
    } else if (location.pathname === "/") {
      return 0;
    } else if (location.pathname === "/about") {
      return 2;
    }
  };

  return (
    <BottomNavigation
      value={navButtonSelectionData ? navButtonSelectionData : value()}
      showLabels
      className={classes.bottomNav}
    >
      <BottomNavigationAction
        className={`MuiBottomNavigationAction  ${classes.NavigationAction}`}
        // classes={"MuiBottomNavigationAction"}
        component={Link}
        to='/'
        label='HOME'
        icon={<HomeIcon />}
        onClick={() => {
          navButtonSelection(0);
        }}
      />

      <BottomNavigationAction
        className={`MuiBottomNavigationAction  ${classes.NavigationAction}`}
        component={Link}
        to='/courses'
        label='COURSE'
        icon={<ListBulletedIcon />}
        onClick={() => {
          navButtonSelection(1);
        }}
      />

      {user.email ? (
        <BottomNavigationAction
          className={`MuiBottomNavigationAction  ${classes.NavigationAction}`}
          component={Link}
          to='/account'
          label='ACCOUNT'
          icon={<SettingsIcon />}
          onClick={() => {
            navButtonSelection(2);
          }}
        />
      ) : (
        <BottomNavigationAction
          className={`MuiBottomNavigationAction  ${classes.NavigationAction}`}
          component={Link}
          to='/about'
          label='ABOUT'
          icon={<InfoIcon />}
          onClick={() => {
            navButtonSelection(2);
          }}
        />
      )}
    </BottomNavigation>
  );
}

const mapStateToProps = (state) => ({
  user: state.userData,
  navButtonSelectionData: state.navData.navButtonSelection,
});

const mapDispatchToProps = { navButtonSelection };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimpleBottomNavigation);
