import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Box,
  Button,
  useTheme,
  Paper,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { connect } from "react-redux";
import TitleHeader from "../../components/titleHeader/TitleHeader";

const useStyles = makeStyles((theme) => ({
  slideFlex: {
    height: "100%",
    overflow: "auto",
    marginBottom: 30,
  },
  bottomButton: {
    position: "fixed",
    bottom: 50,
    width: 600,
    left: "50%",
    transform: "translate(-50%, 0)",
    textAlign: "center",
    zIndex: 1,
    backgroundColor: "white",
    [theme.breakpoints.down("xs")]: {
      bottom: 0,
      paddingBottom: 20,
      width: "100%",
    },
  },
  paper: {
    marginBottom: 10,
    background: "whitesmoke",
  },
}));

const Finish = ({ topicUnit }) => {
  const classes = useStyles();
  const theme = useTheme();
  const slide = topicUnit.slide;

  console.log("topicUnit", topicUnit);
  const topic = topicUnit.topic;
  const info = topicUnit.info;

  console.log("topic", topic);
  console.log("info", info);

  const content =
    slide &&
    slide.map((item, index) => {
      return (
        <Paper className={classes.paper} key={item.content}>
          <ListItem
            button
            key={item.content}
            to={`/slide/${topic}/${info}/${index + 1}`}
            component={RouterLink}
          >
            <ListItemText primary={`${item.content.substring(0, 100)}......`} />
          </ListItem>
        </Paper>
      );
    });

  return (
    <>
      <Box display='flex' flexDirection='column' className={classes.slideFlex}>
        <TitleHeader title='FINISH' />
        <Box display='flex' justifyContent='space-around'>
          <Typography variant='h6'>{topicUnit.info}</Typography>
        </Box>
        <List component='nav' aria-label='secondary mailbox folders'>
          {content}
        </List>
      </Box>

      <Box className={classes.bottomButton}>
        <Button
          size='large'
          component={RouterLink}
          to='/courses'
          className={`${classes.forward} ${classes.icon}`}
          variant='contained'
          style={{
            backgroundColor: theme.palette.secondary.light,
            color: "white",
            fontWeight: "bolder",
            width: "95%",
            // marginLeft: 10,
            // marginRight: 10,
          }}
        >
          COURSES
        </Button>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({ topicUnit: state.publicData.topicUnit });

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Finish);
