import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { editUserData } from "../../redux/action/userAction";

import { useForm } from "react-hook-form";
import { Box, Button, Grid, Typography } from "@material-ui/core";

import { useHistory } from "react-router";

import { fetchCoord, fetchLocation, fetchPosition } from "../../redux/action";
import TitleHeader from "../../components/titleHeader/TitleHeader";
import ValueSelector from "../../components/selector/ValueSelector";
import useValueSelector from "../../components/selector/useValueSelector";

const useStyles = makeStyles({
  root: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  containerBox: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  field: {
    textAlign: "left",
    width: "100%",
  },
  menuItem: {
    textAlign: "left",
  },
  buttonBox: {
    marginTop: 30,
  },
});
const Setup = ({
  fetchLocation,
  fetchCoord,
  mapLocation,
  mapCoord,
  mapPosition,
  userData,
  editUserData,
}) => {
  const classes = useStyles();
  let history = useHistory();
  const { handleSubmit, control } = useForm();

  console.log("userData", userData);

  useEffect(() => {
    fetchLocation();
    fetchCoord();
    fetchPosition();
  }, [fetchLocation, fetchCoord]);

  const [locationSelectValues = "", handleChangeLocation] = useValueSelector(
    userData.location
  );
  const [positionSelectValues = "", handleChangePosition] = useValueSelector(
    userData.position
  );
  const [
    coordinatorSelectValues = "",
    handleChangeCoordinator,
  ] = useValueSelector(userData.coordinator);

  const onSubmit = (data) => {
    editUserData(data);
    history.push("/");

    return console.log(data);
  };

  // will not return anything if user is not populated
  if (!userData.userId) {
    return null;
  }
  return (
    <div>
      <TitleHeader title='ACCOUNT SETUP' />

      <form
        className={classes.root}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box className={classes.containerBox}>
          <Typography variant='h3'>WELCOME</Typography>
          <Box p={3}>
            <Typography variant='h5'>{userData.displayName}</Typography>
          </Box>

          <Box p={1}>
            {mapLocation.length && (
              <Box mt={4}>
                <Box textAlign='left'>
                  <Typography>
                    Select the location/business you're affiliated with.
                  </Typography>
                </Box>
                <ValueSelector
                  name='location'
                  control={control}
                  defaultValue={""}
                  label='Location'
                  valueSelect={locationSelectValues}
                  handleChangeSelect={handleChangeLocation}
                  dataMap={mapLocation}
                />
              </Box>
            )}
            {mapPosition.length && (
              <Box mt={4}>
                <Box textAlign='left'>
                  <Typography>
                    Select what type of worker you consider yourself to be.
                  </Typography>
                </Box>
                <ValueSelector
                  name='position'
                  control={control}
                  defaultValue={positionSelectValues}
                  label='Position'
                  valueSelect={positionSelectValues}
                  handleChangeSelect={handleChangePosition}
                  dataMap={mapPosition}
                />
              </Box>
            )}
            {mapCoord.length && (
              <Box mt={4}>
                <Box textAlign='left'>
                  <Typography>
                    Select a coordinator's name that you're assicated with.
                  </Typography>
                </Box>
                <ValueSelector
                  name='coordinator'
                  control={control}
                  defaultValue={coordinatorSelectValues}
                  label='Coordinator'
                  valueSelect={coordinatorSelectValues}
                  handleChangeSelect={handleChangeCoordinator}
                  dataMap={mapCoord}
                />
              </Box>
            )}
          </Box>
        </Box>

        <Box className={classes.buttonBox}>
          <Grid container justify='space-evenly'>
            <Button color='secondary' variant='outlined' type='submit'>
              Continue
            </Button>
          </Grid>
        </Box>
      </form>
    </div>
  );
};
const mapStateToProps = (state) => {
  const location = state.publicData.location;
  const coord = state.publicData.coord;
  const position = state.publicData.position;

  let mapPosition;
  if (Object.values(position)) {
    mapPosition = Object.values(position).map((item) => {
      return { value: item, label: item };
    });
  }

  let mapLocation;
  if (Object.values(location)) {
    mapLocation = Object.values(location).map((item) => {
      return { value: item.name, label: item.name };
    });
  }

  let mapCoord;
  if (Object.values(coord)) {
    mapCoord = Object.values(coord).map((item) => {
      return { value: item, label: item };
    });
  }

  return {
    userData: state.userData,
    mapLocation,
    mapCoord,
    mapPosition,
  };
};

const mapDispatchToProps = { fetchLocation, editUserData, fetchCoord };

export default connect(mapStateToProps, mapDispatchToProps)(Setup);
