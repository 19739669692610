import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router";

const ProtectedRoutes = ({ children, auth }) => {
  const isLoading = useSelector((state) => state.authData.isLoading);
  console.log("auth", auth);
  console.log("isLoading", isLoading);
  if (isLoading) {
    return null;
  }

  return <>{auth ? children : <Redirect to='/login' />}</>;
};

export default ProtectedRoutes;
