import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { editUserData } from "../../redux/action/userAction";

import { useForm } from "react-hook-form";
import { TextField, Box, Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

import { fetchCoord, fetchLocation, fetchPosition } from "../../redux/action";
import TitleHeader from "../../components/titleHeader/TitleHeader";
import useValueSelector from "../../components/selector/useValueSelector";
import ValueSelector from "../../components/selector/ValueSelector";

const useStyles = makeStyles({
  root: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  containerBox: {
    width: "80%",
    marginLeft: "auto",
    marginRight: "auto",
  },
  field: {
    textAlign: "left",
    width: "100%",
  },
  menuItem: {
    textAlign: "left",
  },
  buttonBox: {
    marginTop: 30,
  },
});
const EditAccount = ({
  fetchLocation,
  fetchCoord,
  fetchPosition,
  mapLocation,
  mapCoord,
  mapPosition,
  userData,
  editUserData,
}) => {
  const classes = useStyles();
  let history = useHistory();
  const { register, handleSubmit, errors, control } = useForm();
  const [
    locationSelectValues = userData.location,
    handleChangeLocation,
  ] = useValueSelector(userData.location);
  const [
    positionSelectValues = userData.position,
    handleChangePosition,
  ] = useValueSelector(userData.position);
  const [
    coordinatorSelectValues = userData.coordinator,
    handleChangeCoordinator,
  ] = useValueSelector(userData.coordinator);

  const onSubmit = (data) => {
    editUserData(data);
    history.push("/account");

    return console.log(data);
  };

  useEffect(() => {
    fetchLocation();
    fetchCoord();
    fetchPosition();
  }, [fetchLocation, fetchCoord, fetchPosition]);

  // will not return anything if user is not populated
  if (!userData.userId) {
    return null;
  }

  return (
    <div>
      <TitleHeader title='EDIT ACCOUNT' />

      <form
        className={classes.root}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box className={classes.containerBox}>
          <Box pt={4}>
            <TextField
              className={classes.field}
              id='outlined-required'
              label='Email'
              defaultValue={userData.email}
              variant='outlined'
              disabled
            />
          </Box>

          <Box pt={4}>
            <TextField
              className={classes.field}
              required
              color='secondary'
              id='outlined-required'
              label='Name'
              defaultValue={userData.displayName}
              variant='outlined'
              name='displayName'
              inputRef={register({ required: true })}
            />
            <Box>{errors.displayName && "Name is required"}</Box>
          </Box>
          <Box p={1}>
            {mapLocation.length && (
              <Box mt={3}>
                <ValueSelector
                  name='location'
                  control={control}
                  defaultValue={locationSelectValues}
                  label='Location'
                  valueSelect={locationSelectValues}
                  handleChangeSelect={handleChangeLocation}
                  dataMap={mapLocation}
                />
              </Box>
            )}
            {mapPosition.length && (
              <Box mt={3}>
                <ValueSelector
                  name='position'
                  control={control}
                  defaultValue={positionSelectValues}
                  label='Position'
                  valueSelect={positionSelectValues}
                  handleChangeSelect={handleChangePosition}
                  dataMap={mapPosition}
                />
              </Box>
            )}
            {mapCoord.length && (
              <Box mt={3}>
                <ValueSelector
                  name='coordinator'
                  control={control}
                  defaultValue={coordinatorSelectValues}
                  label='Coordinator'
                  valueSelect={coordinatorSelectValues}
                  handleChangeSelect={handleChangeCoordinator}
                  dataMap={mapCoord}
                />
              </Box>
            )}
          </Box>
        </Box>

        <Box className={classes.buttonBox}>
          <Grid container justify='space-evenly'>
            <Button
              component={Link}
              color='secondary'
              variant='outlined'
              to='/account'
            >
              Cancel
            </Button>
            <Button color='secondary' variant='outlined' type='submit'>
              Save
            </Button>
          </Grid>
        </Box>
      </form>
    </div>
  );
};
const mapStateToProps = (state) => {
  const location = state.publicData.location;
  const coord = state.publicData.coord;
  const position = state.publicData.position;

  let mapPosition;
  if (Object.values(position)) {
    mapPosition = Object.values(position).map((item) => {
      return { value: item, label: item };
    });
  }
  let mapLocation;
  if (Object.values(location)) {
    mapLocation = Object.values(location).map((item) => {
      return { value: item.name, label: item.name };
    });
  }

  let mapCoord;
  if (Object.values(coord)) {
    mapCoord = Object.values(coord).map((item) => {
      return { value: item, label: item };
    });
  }

  return {
    userData: state.userData,
    mapLocation,
    mapCoord,
    mapPosition,
  };
};

const mapDispatchToProps = {
  fetchLocation,
  editUserData,
  fetchCoord,
  fetchPosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditAccount);
