import { db } from "../../firebase/Firebase";
import _ from "lodash";
import { fetchUserData } from "./userAction";

export const SLIDE_COUNT = "SLIDE_COUNT";

export const slideCount = (payload) => ({
  type: SLIDE_COUNT,
  payload,
});

export const updateCurrentSlide = (topicName, info, slideNum) => (
  dispatch,
  getState
) => {
  const userData = _.cloneDeep(getState().userData);
  let oldTopic = userData[topicName];

  let oldUnit = oldTopic.unit[info];

  topicName = "covid";
  const userId = userData.userId;
  // once you hit the next button, the next slide num is saved
  // this way when you exits the page, you will start on the last page you were on
  let slideNumSaved = slideNum + 1;
  // get the unit's current slide
  const unitsCurrentSlide = oldUnit.currentSlide;

  // compare currentSlide with the slideNumSaved
  // if currentSlide is equal to or less than slideNumSaved
  // do not updatecurrentslide number
  if (slideNumSaved > unitsCurrentSlide) {
    console.log("save unit", slideNumSaved);
    oldUnit.currentSlide = slideNumSaved;
    if (userId) {
      db.collection("usersIPC")
        .doc(userId)
        .update({
          [topicName]: oldTopic,
        })
        .then(function () {
          console.log(`%c DB:Save slide sucessful updated`, "color: #3373FF");
        })
        .then(() => dispatch(fetchUserData()))
        .catch((err) => {
          console.log(
            `%c DB:Document did not update correctly`,
            "color: #3373FF",
            err
          );
        });
    }
  } else {
    console.log("dont save unit");
  }

  oldUnit.currentSlide = slideNumSaved;
};
