import React from "react";
import { Box, Typography } from "@material-ui/core";

const AboutUnit = ({ title, content }) => {
  return (
    <Box mt={2}>
      <Typography variant='h5'>{title}</Typography>
      <Typography>{content}</Typography>
    </Box>
  );
};

export default AboutUnit;
