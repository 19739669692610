import { db } from "../firebase/Firebase";
import { configureStore } from "../redux/storeConfig";
import { FETCH_COVID_LIST } from "../redux/action/publicAction";

export const fetchQueryCovidList = async () => {
  const covidList = await db.collection("covid").doc("covidList").get();
  console.log(`%c DB:fetchQueryCovidList`, "color: #3373FF");
  const data = covidList.data();
  configureStore().dispatch({
    type: FETCH_COVID_LIST,
    payload: data,
  });
  return data;
};
