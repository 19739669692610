import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { editUserData } from "../../../redux/action/userAction";
import { useForm } from "react-hook-form";
import { TextField, Box, Button, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";

import AdminInfoTable from "./AdminInfoTable";

import useValueSelector from "../../../components/selector/useValueSelector";
import TitleHeader from "../../../components/titleHeader/TitleHeader";
import ValueSelector from "../../../components/selector/ValueSelector";

const useStyles = makeStyles({
  root: {
    marginLeft: "auto",
    marginRight: "auto",
    textAlign: "center",
  },
  field: {
    width: "100%",
    textAlign: "left",
  },
  menuItem: {
    textAlign: "left",
  },
  MR: {
    marginRight: 40,
  },
  buttonBox: {
    marginTop: 100,
  },
  containerBox: {
    maxWidth: 360,
    marginTop: 20,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
});
const AdminEditAccount = ({
  mapLocation,
  mapRole,
  mapCoord,
  mapPosition,
  editUserData,
  filteredUserData,
  UserData,
}) => {
  const classes = useStyles();
  const { register, handleSubmit, errors, control } = useForm();

  const [roleSelectValues, handleChangeRole] = useValueSelector(UserData.role);
  const [locationSelectValues, handleChangeLocation] = useValueSelector(
    UserData.location
  );
  const [positionSelectValues, handleChangePosition] = useValueSelector(
    UserData.position
  );
  const [coordinatorSelectValues, handleChangeCoordinator] = useValueSelector(
    UserData.coordinator
  );

  const onSubmit = (data) => {
    editUserData(data, UserData.userId);
    return console.log("submitted data", data);
  };

  return (
    <div>
      <TitleHeader title='ADMIN EDIT ACCOUNT' />
      <AdminInfoTable filteredUserData={filteredUserData} />
      <form
        className={classes.root}
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box component={Paper} className={classes.containerBox}>
          <Box pt={4}>
            <TextField
              size='small'
              className={classes.field}
              id='outlined-required'
              label='Email'
              defaultValue={UserData.email}
              disabled
              color='secondary'
            />
          </Box>

          <Box pt={4}>
            <TextField
              size='small'
              className={classes.field}
              required
              id='outlined-required'
              label='Name'
              defaultValue={UserData.displayName}
              name='displayName'
              inputRef={register({ required: true })}
              color='secondary'
            />
            <Box>{errors.displayName && "Name is required"}</Box>
          </Box>

          {mapLocation.length && (
            <ValueSelector
              name='location'
              control={control}
              defaultValue={locationSelectValues}
              label='Location'
              valueSelect={locationSelectValues}
              handleChangeSelect={handleChangeLocation}
              dataMap={mapLocation}
            />
          )}

          {mapPosition.length && (
            <ValueSelector
              name='position'
              control={control}
              defaultValue={positionSelectValues}
              label='Position'
              valueSelect={positionSelectValues}
              handleChangeSelect={handleChangePosition}
              dataMap={mapPosition}
            />
          )}

          {mapCoord.length && (
            <ValueSelector
              name='coordinator'
              control={control}
              defaultValue={coordinatorSelectValues}
              label='Coordinator'
              valueSelect={coordinatorSelectValues}
              handleChangeSelect={handleChangeCoordinator}
              dataMap={mapCoord}
            />
          )}

          {mapRole.length && (
            <ValueSelector
              name='role'
              control={control}
              defaultValue={roleSelectValues}
              label='Role'
              valueSelect={roleSelectValues}
              handleChangeSelect={handleChangeRole}
              dataMap={mapRole}
            />
          )}
        </Box>

        <Box className={classes.buttonBox}>
          <Button
            component={Link}
            color='secondary'
            variant='outlined'
            to='/admin'
            className={classes.MR}
          >
            Cancel
          </Button>
          <Button color='secondary' variant='outlined' type='submit'>
            Save
          </Button>
        </Box>
      </form>
    </div>
  );
};
const mapStateToProps = (state, ownProps) => {
  const ownPropUserId = ownProps.match.params.userId;

  const allUser = state.adminData.allUser;
  const location = state.publicData.location;
  const role = state.publicData.role;
  const coord = state.publicData.coord;
  const position = state.publicData.position;
  // get all the user data and filter out that user data
  // that matches the userId paramater from the link

  const UserData = allUser.filter((item) => {
    return item.userId === ownPropUserId;
  })[0];

  const filteredUserData = Object.values(UserData.covid.unit);

  //create an object array out of location data
  let mapLocation;
  if (Object.values(location)) {
    mapLocation = Object.values(location).map((item) => {
      return { value: item.name, label: item.name };
    });
  }

  let mapRole;
  if (Object.values(role)) {
    mapRole = Object.values(role).map((item) => {
      return { value: item, label: item };
    });
  }

  let mapCoord;
  if (Object.values(coord)) {
    mapCoord = Object.values(coord).map((item) => {
      return { value: item, label: item };
    });
  }

  let mapPosition;
  if (Object.values(position)) {
    mapPosition = Object.values(position).map((item) => {
      return { value: item, label: item };
    });
  }

  return {
    filteredUserData,
    mapLocation,
    UserData,
    mapRole,
    mapCoord,
    mapPosition,
  };
};

const mapDispatchToProps = {
  editUserData,
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminEditAccount);
