import React from "react";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { fetchTopicUnit } from "../../redux/action/publicAction";
import LinearProgressBar from "./LinearProgressBar";

import ButtonArrow from "./ButtonArrow";
import PropTypes from "prop-types";
import { updateCurrentSlide } from "../../redux/action/slideAction";
import { useParams } from "react-router";

const useStyles = makeStyles((theme) => ({
  saveProgress: { marginLeft: "auto" },

  slideFlex: {
    height: "100%",
    overflow: "auto",
    marginBottom: 30,
  },

  bottomButton: {
    position: "fixed",
    bottom: 50,
    width: 600,
    left: "50%",
    transform: "translate(-50%, 0)",
    zIndex: 1,

    backgroundColor: "white",
    [theme.breakpoints.down("xs")]: {
      bottom: 0,
      paddingBottom: 20,
      width: "100%",
    },
  },
  contentText: {
    fontSize: 18,
  },
}));

const Slide = ({ fetchTopicUnit, topicUnit, updateCurrentSlide, user }) => {
  const classes = useStyles();

  // passing unit and topic from topic unit link
  const { topic, info, slidenum = 1 } = useParams();

  let slide;
  let content;
  let numberOfSlide;
  const [isLoading, setIsLoading] = React.useState(true);

  // this converts slide number into object index number
  const [page, setPage] = React.useState(parseInt(slidenum) - 1 || 0);

  // fetch data from firebase server
  React.useEffect(() => {
    fetchTopicUnit(topic, info).then(() => {
      setIsLoading(false);
    });
  }, [topic, info, fetchTopicUnit]);

  const handleBackwardClick = () => {
    setPage((page) => page - 1);
  };
  const handleForwardClick = () => {
    setPage((page) => page + 1);
    // page is converted into slide number and not index number
    // this should only be called if userData slide is higher what is already saved
    // for the current user data unit
    // updateCurrent Slide should not be called if there's no user
    if (user.userId) {
      updateCurrentSlide(topic, info, page + 1);
    }
  };

  const LoadingPlaceHolder = (
    <div data-test='test-loadingPlaceHolder'>
      <Box pt={3} />
      <Skeleton animation='wave' height={50} />
      <Skeleton animation='wave' />
      <Box pt={3} />
      <Skeleton variant='rect' height={200} />
    </div>
  );

  // once topicUnit is populated, set the slide, content and numberOfSlide

  if (!isLoading) {
    slide = topicUnit.slide;
    // find the content info
    content = slide[page].content;

    // find the slide count page
    numberOfSlide = slide.length;
  }

  const contentMap = (
    <div data-test='test-contentMap'>
      <Box py={2}>
        <Typography variant='h5'>{topicUnit.info}</Typography>
        <Typography variant='body1'>Slide {page + 1}</Typography>
      </Box>
      <Box className={classes.marginBottom}>
        <Typography className={classes.contentText}>{content}</Typography>
      </Box>
    </div>
  );

  return (
    <div data-test='test-main'>
      <Grid
        container
        justify='space-around'
        alignItems='stretch'
        direction='column'
      >
        <Grid item xs={12}>
          <Box
            display='flex'
            justifyContent='center'
            flexDirection='column'
            className={classes.slideFlex}
          >
            <LinearProgressBar
              numberOfSlide={numberOfSlide}
              page={page + 1}
              topicUnitInfo={topicUnit.info}
              data-test='test-progressbar'
            />

            <Box data-test='test-content'>
              {isLoading ? LoadingPlaceHolder : contentMap}
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.bottomButton}>
        <ButtonArrow
          backwardClick={handleBackwardClick}
          forwardClick={handleForwardClick}
          backwardDisable={page < 1 ? true : false}
          forwardDisable={numberOfSlide === page + 1 ? true : false}
          data-test='test-arrow-buttons'
        />
      </Box>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.userData,
  topicUnit: state.publicData.topicUnit,
  slidePage: state.slideData.slide_counter,
});

const mapDispatchToProps = {
  fetchTopicUnit,
  updateCurrentSlide,
};

Slide.propTypes = {
  topicUnit: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Slide);
