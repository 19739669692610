import { firebase } from "../../../firebase/Firebase";
import { fetchUserData } from "../../../redux/action/userAction";

export const SIGN_IN_USER = "SIGN_IN_USER";
export const SIGN_OUT_USER = "SIGN_OUT_USER";
export const NEW_USER_SIGNIN = "NEW_USER_SIGNIN";
export const IS_LOADING = "IS_LOADING";

export const signInUser = (user) => {
  return { type: SIGN_IN_USER, payload: user };
};

export const signOutUser = (payload) => ({
  type: SIGN_OUT_USER,
  payload,
});

export const newUserSignInSwitch = (value) => {
  return { type: NEW_USER_SIGNIN, payload: value };
};

export const isLoading = (value) => {
  return { type: IS_LOADING, payload: value };
};

// this function creates a listener to watch out for signed in or signed out user
export const verifyAuth = () => (dispatch) => {
  dispatch(isLoading(true));
  console.log("verifyAuth");

  firebase.auth().onAuthStateChanged((user) => {
    console.log(`%c DB:verifyAuth called`, "color: #3373FF", user);

    if (user) {
      console.log(
        `%c DB:verifyAuth detected login user`,
        "color: #3373FF",
        user
      );
      //user is signed in and auth this verified
      dispatch(signInUser(user));
      // get the user's data from the firestore pass in the uid
      dispatch(fetchUserData());
      dispatch(isLoading(false));
    } else {
      console.log(`%c DB:verifyAuth detect no login user`, "color: #3373FF");

      dispatch(signOutUser());
      dispatch(isLoading(false));
    }
  });
};

export const googleLogin = () => async (dispatch, getState) => {
  const provider = new firebase.auth.GoogleAuthProvider();

  try {
    const result = await firebase.auth().signInWithPopup(provider);
    console.log(`%c DB:googleLogin successful`, "color: #3373FF");

    if (result.additionalUserInfo.isNewUser) {
      dispatch({ type: NEW_USER_SIGNIN, payload: true });

      // fetchUserData every 3 seconds until userData is populated
      // onAuthStateChange doesn't detect new user so you need to
      // fetch userData until is populated by cloud function
      // this timeInterval will only run 5 times for 3 seconds
      // a total of 3 runs in 15 seconds
      let timeKeeper = 0;
      let createTimeKeeper = setInterval(() => {
        console.log("timeKeeper, dispatch fetchUserData", timeKeeper);

        timeKeeper++;
        dispatch(fetchUserData());
        console.log("getState().userData", getState().userData);

        // clear interval is called when user data is populated
        if (getState().userData.email) {
          clearInterval(createTimeKeeper);
          console.log("clearedInterval, userData populated");
        }
        // clear interval is called 3 fetch is tried within 9 seconds
        if (timeKeeper === 5) {
          clearInterval(createTimeKeeper);
          console.log(
            "clearedInterval, unable to create new account, stop fetching userData"
          );
        }
      }, 3000);
    }
  } catch (error) {
    console.log(`%c DB:googleLogin error`, error, "color: #3373FF");
  }
};

// export const googleLogin = async () => {
//   console.log("googleLoginClick");
//   const provider = new firebase.auth.GoogleAuthProvider();
//   const result = await firebase.auth().signInWithPopup(provider);

//   try {
//     console.log("googlelogin", result);
//     return function (dispatch) {
//       if (result.additionalUserInfo.isNewUser) {
//         // fetch user data for new user
//         console.log("fetch new user data");
//         dispatch(fetchUserData(result));
//       }
//     };
//   } catch (error) {}
// };
